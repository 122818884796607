<template>
    <div class="user__content subscription">
        <Loader v-if="loading"></Loader>
        <div v-if="!loading" class="subscription__container user__inner">
            <div class="subscription__wrapper user__item">
                <div class="subscription__title lk-title">Активные подписки</div>
                <div class="subscription__table s-table">
                    <div class="s-table__header">
                        <div class="s-table__date fw-m">Дата</div>
                        <div class="s-table__method fw-m">Платежный метод</div>
                        <div class="s-table__amount fw-m">Сумма</div>
                    </div>
                    <div class="pr-item__line"></div>
                    <div class="s-table__content">
                        <div v-if="subscription" class="s-table__item">
                            <div class="s-table__date s-table__line">{{ date }}</div>
                            <div class="s-table__method s-table__line">
                                <div class="s-table__img card" :class="cardtype"></div>
                                <div class="s-table__txt">{{ card }}</div>
                            </div>
                            <div class="s-table__amount s-table__line">{{ sum }} руб.</div>
                            <button v-if="active" class="s-table__btn" @click="cancel">
                                <div class="txt-primary">Отменить подписку</div>
                            </button>
                            <div v-else class="s-table__btn" style="text-align: center;">
                                <div style="color:green;">Подписка отменена</div>
                            </div>
                        </div>
                        <div v-else class="s-table__item" style="justify-content: center;">
                            Нет активных подписок
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class='flex-center'>
        <ins class="dd15595d" data-key="322af549cf4fb976e701339aca211d90"></ins>
    </div>
</template>

<script>
    import Loader from './Loader'
    import * as Auth from '@/assets/js/auth.js'
    import CardImages from '@/assets/css/cards.css'

    export default {
        name: 'Subscription',
        components: {Loader},

        data: () => ({
            loading: true,
            subscription: false,
            date: '',
            card: '',
            sum: 0,
            cardtype: '',
            active: '',
        }),
        
        methods: {
            cancel() {
                Auth.load('/client/unsubsrcibe');
                this.active = false;
            }
        },

        mounted() {
            if (!Auth.getToken()) {
                return this.$router.replace({name: 'Login'});
            }

            Auth.load('/client/subscription').then(data => {
                this.loading = false;
                if (data.subscription !== null) {
                    this.subscription = true;
                    this.date = data.subscription.date;
                    this.card = data.subscription.card;
                    this.sum = data.subscription.sum;
                    this.cardtype = data.subscription.cardtype;
                    this.active = data.subscription.active;
                }
            }).catch(() => {
                Auth.logout();
                this.$router.replace({name: 'Login'});
            });
        }
    }
</script>

