<template>
    <div class="loader__wrapper">
        <div class="loader__center">
            <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Loader',
    }
</script>

<style scoped>
    .lds-ring {
        display: inline-block;
        position: relative;
        width: 80px;
        height: 80px;
    }
    .lds-ring div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 64px;
        height: 64px;
        margin: 8px;
        border: 8px solid #696969;
        border-radius: 50%;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: #696969 transparent transparent transparent;
    }
    .lds-ring div:nth-child(1) {animation-delay: -0.45s;}
    .lds-ring div:nth-child(2) {animation-delay: -0.3s;}
    .lds-ring div:nth-child(3) {animation-delay: -0.15s;}
    @keyframes lds-ring {0% {transform: rotate(0deg);} 100% {transform: rotate(360deg);}}
    
    .loader__wrapper {
        width: 100%;
        height: 100vh;
        z-index: 999;
        position: absolute;
        background: #fff;
    }
    .loader__center {
        display: flex;
        height: 100%;
        justify-content: center;
        align-items: center;
    }
</style>
