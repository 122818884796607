import Login from '@/components/Login.vue';
import { createWebHistory, createRouter } from 'vue-router';
import Profile from '@/components/Profile.vue';
import History from '@/components/History.vue';
import CreditCards from '@/components/CreditCards.vue';
import DebitCards from '@/components/DebitCards.vue';
import Bankru from '@/components/Bankru.vue';
import Subscription from '@/components/Subscription.vue';
import Showcase from '@/components/Showcase.vue';

const routes = [
    {
        path: '/',
        name: 'Login',
        component: Login,
    },
    {
        path: '/profile',
        name: 'Profile',
        component: Profile,
    },
    {
        path: '/history',
        name: 'History',
        component: History,
    },
    {
        path: '/subscription',
        name: 'Subscription',
        component: Subscription,
    },
    {
        path: '/showcase',
        name: 'Showcase',
        component: Showcase,
        props: true
    },
    {
        path: '/creditCards',
        name: 'CreditCards',
        component: CreditCards,
        props: true
    },
    {
        path: '/debitCards',
        name: 'DebitCards',
        component: DebitCards,
        props: true
    },
    {
        path: '/bankru',
        name: 'Bankru',
        component: Bankru,
    },
    {
        path: '/:pathMatch(.*)*',
        component: Login,
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
