<style scoped>
img {
  display: inline-block;
}
.login-form__lower-txt {
  margin-top: 20px;
  margin-bottom: 20px;
}
.login-form__lower-txt a {
  color: #000;

  font-family: Jost;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration-line: underline;
}
.logo {
  left: 0%;
  position: relative;
  top: 12px;
  width: 50%;
}
.btn-mts {
  background: #1d2023;
  transition: all 0.5s ease;
  border: 3px solid transparent;
  border-spacing: initial;
  border-collapse: separate;
}

.btn-send-full {
  padding: 14px 22px;
  border-radius: 8px;
  font-size: 16px;
  display: block;
}
.mts-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.login-title {
  background: var(
    --g1,
    linear-gradient(0deg, #007edf -37.76%, #35c12c 104.41%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: Jost;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
  margin-top: 45px;
}
.login-subtitle {
  color: #000;

  font-family: Jost;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  font-family: Jost;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 20px;
  margin-bottom: 8px;
}
.phone-input {
  margin-bottom: 20px;
}
.login-form-wrapper {
  align-items: center;
}
.login-form-btn {
  border-radius: 8px;
  background: linear-gradient(90deg, #1193a7 0%, #31bb38 100%);

  padding: 16px 75px;
  text-align: center;
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  font-family: jost;
}
.login-form-btn-register {
  border-radius: 8px;
  background: linear-gradient(90deg, #1193a7 0%, #31bb38 100%);
  margin-top: 15px;
  padding: 15px 20px;
  text-align: center;
  color: #fff;
  font-weight: 500;
  font-size: 10px;
  font-family: jost;
}
.login-form-code-wrapper {
  margin-left: 90px !important;
}
.line-wrap {
  display: flex;
  align-items: center;
}
.line {
  height: 1px;
  border: 1px solid #bbc1c7;
  width: 65px;
}
.seperator {
  margin: 0 15px;
}
</style>
<style lang="scss">
dialog {
  padding: 25px;
  border-radius: 20px;
  background: #efefef;
  border: 0;
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.1);
  margin: 0 auto;
  top: 20%;
  animation: fadeIn 1s ease both;
  &::backdrop {
    animation: fadeIn 1s ease both;
    background: rgba(255, 254, 254, 0.4);
    z-index: 2;
    backdrop-filter: blur(5px);
  }
  .x {
    filter: grayscale(1);
    border: none;
    background: none;
    position: absolute;
    top: 15px;
    right: 10px;
    transition: ease filter, transform 0.3s;
    cursor: pointer;
    transform-origin: center;
    &:hover {
      filter: grayscale(0);
      transform: scale(1.1);
    }
  }
  .dialog__title {
    color: #000;
    font-family: Jost;
    font-size: 11px;
    font-style: normal;
    font-weight: 700;
  }
}
</style>
<template>
  <div class="login-page">
    <div class="container">
      <dialog id="dialog">
        <div style="text-align: center" class="dialog__text">
          Номер телефона не найден. <br />
          Пожалуйста, зарегистрируйтесь по кнопке ниже <br />
          <div class="btn login-form__btn">
            <a
              class="login-form-btn-register"
              href="https://365zaym.su/anketa"
              target="_blank"
              ><div class="txt txt-white fw-smb">ЗАРЕГИСТРИРОВАТЬСЯ</div></a
            >
          </div>
        </div>
        <button onclick="window.dialog.close();" aria-label="close" class="x">
          ❌
        </button>
      </dialog>
      <div class="text-center login-title">Войти в личный кабинет</div>
      <div class="login-page__center">
        <div v-show="page == 'phone'" class="login-page__form login-form">
          <div class="row"></div>
          <div class="row flex-column login-form-wrapper" id="loginformInput">
            <div class="text-center login-subtitle">Введите номер телефона</div>

            <q-input
              v-model="phone"
              rounded
              outlined
              placeholder="+7 (901) 234-5678"
              mask="+7 (###) ###-####"
              color="green"
              @keyup.enter="entry"
              style="margin-top: 10px"
              class="d-flex phone-input flex-column align-items-center"
            >
            </q-input>
          </div>
          <div class="error" v-if="error !== null">{{ error }}</div>
          <div class="row" id="loginformButton">
            <button class="btn login-form__btn">
              <a class="login-form-btn" @click="entry"
                ><div class="txt txt-white fw-smb">ВОЙТИ</div></a
              >
            </button>
          </div>

          <div class="line-wrap">
            <div class="line"></div>
            <div class="seperator">или</div>
            <div class="line"></div>
          </div>
          <div class="row" id="">
            <button
              class="btn login-form__btn mts-button"
              @click="() => oauth2('mts')"
            >
              <a class="btn-mts btn-send-full"
                ><div class="txt mts-wrapper txt-white fw-smb">
                  <img src="/img/mtsid-full.svg" alt="mts" /></div
              ></a>
            </button>
          </div>
        </div>

        <div v-show="page == 'code'" class="login-page__form login-form">
          <div class="row flex-between" id="loginformChange">
            <div class="login-form__code-label">
              <span>Вам отправлен код на номер: </span>
              <span class="txt-no-wrap" id="loginformNumber">{{ phone }}</span>
            </div>
            <div class="login-form__button-change">
              <a class="txt-primary" href="#" role="button" @click="reset"
                >Изменить</a
              >
            </div>
          </div>
          <div
            class="row flex-column login-form-code-wrapper"
            id="loginformInput"
          >
            <q-input
              color="green"
              v-model="code"
              autocomplete="off"
              ref="loginCode"
              id="loginCode"
              style=""
              rounded
              outlined
              mask="######"
              class="login-code-input"
              placeholder="_ _ _ _ _ _"
            />
          </div>
          <div class="error" v-if="error !== null">{{ error }}</div>

          <div
            class="login-form__lower-txt"
            v-if="timeDifference >= 0 && retry >= 10"
          >
            <span>{{ timerOutput }}</span>
          </div>
          <div
            class="login-form__lower-txt"
            v-if="timeDifference >= 0 && retry < 10"
          >
            Получить новый код можно через {{ timerOutput }}
          </div>
          <div
            v-if="timeDifference <= 0 && retry < 10"
            class="login-form__lower-txt"
          >
            <a class="txt-primary" href="#" role="button" @click="resend"
              >Получить код повторно</a
            >
          </div>
          <div class="row" id="loginformButton">
            <button class="btn login-form__btn">
              <a class="login-form-btn" @click="confirm"
                ><div class="txt txt-white fw-smb">Войти</div></a
              >
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Inputmask from "inputmask";
import * as Auth from "@/assets/js/auth.js";

export default {
  name: "Login",

  data: () => ({
    phone: "",
    code: "",
    phoneValid: true,
    error: null,
    page: "phone",
    confirmAutoSended: false,
    countDownToTime: 0,
    timeDifference: 0,
    timerOutput: null,
    interval: null,
    retry: 0,
  }),
  watch: {
    timeDifference(val) {
      if (val < 2) {
        clearInterval(this.interval);
      }
    },
  },
  methods: {
    oauth2(client) {
      Auth.oauth2(client);
    },
    entry() {
      if (!this.phoneValidate()) {
        return;
      }
      this.startTimer();
      this.retry++;
      Auth.entry(this.phone, location.hostname.replace("lk.", "")).then(
        (response) => {
          this.error = response.error;
          if (response.error === null) {
            this.page = "code";
            setTimeout(() => this.$refs.loginCode.focus(), 1);
          }
          if(response.error === 'Номер телефона не найден') {
            window.dialog.showModal();
          }
        }
      );
    },

    confirm() {
      Auth.confirm(this.code).then((response) => {
        this.error = response.error;
        if (response.error === null) {
          this.$router.replace({ name: "Profile" });
        }
      });
    },

    reset() {
      this.phone = "";
      this.code = "";
      this.page = "phone";
      this.confirmAutoSended = false;
      setTimeout(() => this.$refs.loginPhone.focus(), 1);
    },

    resend() {
      this.entry();
      this.code = "";
      this.startTimer();
      this.retry++;
    },

    startTimer() {
      this.countDownToTime = new Date().getTime() + 10000;
      this.interval = setInterval(() => {
        const timeNow = new Date().getTime();
        this.timeDifference = this.countDownToTime - timeNow;
        const millisecondsInOneSecond = 1000;
        const millisecondsInOneMinute = millisecondsInOneSecond * 60;
        const millisecondsInOneHour = millisecondsInOneMinute * 60;
        const millisecondsInOneDay = millisecondsInOneHour * 24;
        const differenceInDays = this.timeDifference / millisecondsInOneDay;
        const remainderDifferenceInHours =
          (this.timeDifference % millisecondsInOneDay) / millisecondsInOneHour;
        const remainderDifferenceInMinutes =
          (this.timeDifference % millisecondsInOneHour) /
          millisecondsInOneMinute;
        const remainderDifferenceInSeconds =
          (this.timeDifference % millisecondsInOneMinute) /
          millisecondsInOneSecond;
        const remainingDays = Math.floor(differenceInDays);
        const remainingHours = Math.floor(remainderDifferenceInHours);
        const remainingMinutes = Math.floor(remainderDifferenceInMinutes);
        const remainingSeconds = Math.floor(remainderDifferenceInSeconds);
        this.timerOutput =
          (remainingMinutes < 10 ? "0" + remainingMinutes : remainingMinutes) +
          ":" +
          (remainingSeconds < 10 ? "0" + remainingSeconds : remainingSeconds);
      }, 1);
    },

    phoneValidate() {
      var isValid = this.phone.replace("_", "").length === 17;
      this.phoneValid = isValid;
      
      this.error = isValid ? null : "Введите номер полностью";
      return isValid;
    },
  },

  mounted() {
    if (Auth.getToken()) {
      this.$router.replace({ name: "Profile" });
    }

    if (this.$route.query.error === "user_not_found") {
      window.dialog.showModal();
    }
  
  },
};
</script>
<style>
.phone-input .q-field__control {
  width: 100% !important;
}
.phone-input .q-field__native {
  text-align: center !important;
  margin-left: -5px;
}
.login-code-input .q-field__native {
  text-align: center !important;
}
.login-code-input .q-field__control {
  width: 65% !important;
}
.header-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  align-items: center;
}
.logo-wrapper {
  width: 15%;
}
@media (max-width: 769px) {
  .logo-wrapper {
    width: 35%;
  }
}
</style>
