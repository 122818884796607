<template>
    <div class="user__content operations">
        <Loader v-if="loading"></Loader>
        <div v-if="!loading" class="operations__container user__inner">
            <div class="operations__wrapper user__item">
                <div class="operations__title lk-title">Операции с заявкой</div>
                <div class="operations__table o-table">
                    <div class="o-table__header">
                        <div class="o-table__order fw-m">#</div>
                        <div class="o-table__date fw-m">Дата</div>
                        <div class="o-table__text fw-m">Событие</div>
                    </div>
                    <div class="pr-item__line"></div>
                    <div class="o-table__content">
                        <div v-for="item in historyItems" class="o-table__item">
                            <div class="o-table__order">{{ item.id }}</div>
                            <div class="o-table__date">{{ item.date }}</div>
                            <div class="o-table__text">{{ item.text }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Loader from './Loader'
    import * as Auth from '@/assets/js/auth.js'

    export default {
        name: 'History',
        components: {Loader},

        data: () => ({
            loading: true,
            historyItems: [],
        }),

        mounted() {
            if (!Auth.getToken()) {
                return this.$router.replace({name: 'Login'});
            }

            Auth.load('/client/history').then(data => {
                this.loading = false;
                this.historyItems = data;
            }).catch(() => {
                Auth.logout();
                this.$router.replace({name: 'Login'});
            });
        }
    }
</script>
