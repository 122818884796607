<style>
.showcase-wrapper {
  display: flex;
  flex-direction: column;
  padding-bottom: 25px;
}
.credit-card {
  background: #fff;
  padding: 24px 74px 24px 26px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}
.debit-info {
  display: flex;
  flex-direction: column;
  margin-left: -95px;
}
.data {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.info-wrapper {
  display: flex;
  flex-direction: column;
}
.info-wrapper:not(:first-child) {
  margin-left: 70px;
}

.info-title {
  color: #757575;

  font-family: "Jost";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}
.info-value {
  color: #000;
  margin-top: 5px;
  font-family: "Jost";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
}
.privileges {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.privilege {
  padding: 5px 10px;
  border-radius: 300px;
  background: #efefef;
  text-align: center;
  color: #757575;

  font-family: "Jost";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}
.privilege:not(:first-child) {
  margin-left: 10px;
}

.credit-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  font-size: 14px;
  padding: 10px 15px;
  font-weight: 500;
  border-radius: 5px;
  background: linear-gradient(
    90deg,
    rgba(17, 147, 167, 1) 0%,
    rgba(49, 187, 56, 1) 100%
  );
  position: relative;
  z-index: 1;
  transition: 0.4s all ease-in-out;
  cursor: pointer;
}
.credit-btn:hover {
  color: #fff;
  text-decoration: none;
  opacity: 0.85;
}
.data-first,
.data-second {
  display: flex;
  align-items: center;
}
.data-second {
  margin-left: 70px;
}
.license-desktop {
  display: block;
  color: #757575;

  font-family: "Jost";
  font-size: 8px;
  font-style: normal;
  font-weight: 400;

  margin-top: 5px;
}
.license-mobile {
  display: none;
}

/* MEDIA */
@media (max-width: 1495.98px) {
  .credit-card {
    padding: 24px 20px;
  }
  .credit-card img {
    max-width: 90%;
  }
}
@media (max-width: 992px) {
  .debit-info {
    margin-left: -50px;
  }
  .credit-card img {
    max-width: 70%;
  }
  .credit-btn {
    font-size: 10px;
  }
  .info-wrapper:not(:first-child) {
    margin-left: 40px;
  }
  .data-second {
    margin-left: 40px;
  }
  .info-title {
    font-size: 8px;
  }
  .info-value {
    font-size: 10px;
  }

  .privilege {
    padding: 5px 10px;
    border-radius: 300px;
    background: #efefef;
    text-align: center;

    font-family: "Jost";
    font-size: 8px;
  }
}
@media (max-width: 767.98px) {
  .credit-card {
    flex-direction: column;
    align-items: flex-start;
  }
  .data {
    flex-direction: column;
    align-items: flex-start;
  }
  .info-wrapper:not(:first-child) {
    margin-left: 0px;
  }
  .debit-info,
  .data-second {
    margin-left: 0;
  }
  .debit-info {
    width: 100%;
  }
  .data-first,
  .data-second {
    width: 100%;
    justify-content: space-between;
  }
  .data-first {
    margin-bottom: 25px;
    margin-top: 25px;
  }
  .period-value,
  .percent-value {
    text-align: end;
  }
  .privileges {
    margin-bottom: 25px;
    flex-wrap: wrap;
    width: 90%;
  }
  .privilege {
    margin-top: 25px;
  }
  .privilege:last-child {
    margin-top: 25px;
  }
  .license-desktop {
    display: none;
  }
  .credit-logo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    
  }
  .license-mobile {
    display: block;
    color: #757575;

    font-family: "Jost";
    font-size: 8px;
    font-style: normal;
    font-weight: 400;
  }
}
@media (max-width: 480px) {

  .privilege {
    margin-top: 25px;
  }
  .privilege:last-child {
    margin-left: 0px;
    margin-top: 10px;
  }
}
@media (min-width: 0) {
  .container {
    max-width: 340px;
  }
}
@media (min-width: 479.98px) {
  .container {
    max-width: 490px;
  }
}
@media (min-width: 767.98px) {
  .container {
    max-width: 720px;
  }

  .showcase-wrapper {
    padding-right: 0px;
  }
}
@media (min-width: 991.98px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1495.98px) {
  .container {
    max-width: 1110px;
  }
}

.user .lk-title {
  text-align: center;
  font-size: 20px;
}
.showcase-title-txt{
  margin-bottom: 10px;
}
</style>

<template>
  <div class="user__content showcase">
    <Loader v-if="loading"></Loader>

    <div class="showcase-wrapper container">
      <div class="showcase-title lk-title">
        <div class="showcase-title-txt">
          Мы подобрали для вас актуальные займы на {{ date }}
        </div>
        <div @click="showcaseSort" id="sorting-btn" class="sort-title">
          <div class="sort-title-wrapper">
            <div class="icon">
              <img src="/img/sort.svg" alt="" />
            </div>
            <div class="name">
              Сортировать
            </div>
            <div
              :class="{ toggled: showcaseSortActive }"
              class="dropdown-arrow"
            >
              <img src="/img/down-arrow.svg" alt="" />
            </div>
          </div>
        </div>
        <div class="sort-menu" id="sorting" v-show="showcaseSortActive">
          <div class="sort-menu-wrapper">
       
            <div @click="showcaseSortPercentUp" class="sort-btn">
              <div class="sort-img">
                <img src="/img/arrow-up.svg" alt="" />
              </div>
              <div class="sort-txt">
                По проценту (возр.)
              </div>
            </div>
            
            <div @click="showcaseSortPercentDown" class="sort-btn">
              <div class="sort-img">
                <img src="/img/arrow-down.svg" alt="" />
              </div>
              <div class="sort-txt">
                По проценту (убыв.)
              </div>
            </div>
            <div @click="showcaseSortCashbackUp" class="sort-btn">
              <div class="sort-img">
                <img src="/img/arrow-up.svg" alt="" />
              </div>
              <div class="sort-txt">
                По кэшбеку (возр.)
              </div>
            </div>
            <div @click="showcaseSortCashbackDown" class="sort-btn">
              <div class="sort-img">
                <img src="/img/arrow-down.svg" alt="" />
              </div>
              <div class="sort-txt">
                По кэшбеку (убыв.)
              </div>
            </div>
            <div @click="showcaseSortServiceUp" class="sort-btn">
              <div class="sort-img">
                <img src="/img/arrow-up.svg" alt="" />
              </div>
              <div class="sort-txt">
                По обслуживанию (возр.)
              </div>
            </div>
       

           
            <div @click="showcaseSortServiceDown" class="sort-btn">
              <div class="sort-img">
                <img src="/img/arrow-down.svg" alt="" />
              </div>
              <div class="sort-txt">
                По обслуживанию (убыв.)
              </div>
            </div>
            
          </div>
        </div>
      </div>
      <div v-for="showcase, index in showcaseItemsParts" class="credit-card">
        <div class="credit-logo">
          <div class="logo">
            <img :src="showcase.logo" alt="logo" />
          </div>
          <div class="license-mobile">
            {{ showcase.license }}
          </div>
        </div>
        <div class="debit-info">
          <div class="data">
            <div class="data-first">
              <div class="naming info-wrapper">
                <div class="naming-title info-title">
                  Наименование банка
                </div>
                <div class="naming-name info-value">
                  {{ showcase.bank_name }}
                </div>
              </div>
              <div class="period info-wrapper">
                <div class="period-title info-title">
                  Процент на остаток
                </div>
                <div class="period-value info-value">
                  {{ showcase.percent }}%
                </div>
              </div>
            </div>
            <div class="data-second">
              <div class="limit info-wrapper">
                <div class="limit-title info-title">
                  Кэшбек
                </div>
                <div class="limit-value info-value">
                  {{ showcase.cashback }}%
                </div>
              </div>
              <div class="percent info-wrapper">
                <div class="percent-title info-title">
                  Обслуживание
                </div>
                <div class="percent-value info-value">
                 {{ showcase.service }} ₽
                </div>
              </div>
            </div>
          </div>
          <div class="license-desktop">
            {{ showcase.license }}
          </div>
          <div class="privileges">
            <div class="privilege">{{ showcase.tag1 }}</div>
            <div class="privilege">{{ showcase.tag2 }}</div>
            <div class="privilege">{{ showcase.tag3 }}</div>
          </div>
        </div>
        <div class="credit-btn" :class="{ visited: visitedLinks.includes(index) }" @click="goToLink(showcase, index)">
          <span v-if="!visitedLinks.includes(index)">{{ text }}</span>
          <span v-if="visitedLinks.includes(index)" class="visited-text">{{ textVisited }}</span>
        </div>
      </div>
      <div class="show-more-wrapper">
        <div
          v-if="showcaseCounter < showcaseItems.length"
          @click="showcaseCounter += 4"
          class="partner-btn-show-more mb-20 mt-20"
        >
          Показать еще
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Loader from "./Loader";
import * as Auth from "@/assets/js/auth.js";
import StarRating from "@/components/StarRating";
export default {
  name: "DebitCards",
  components: { Loader, StarRating },

  data: () => ({
    loading: true,
    date: null,
    firstname: null,
    showcaseItems: [],
    showcaseCounter: 4,
    showcaseSortActive: false,
    value: null,
    visitedLinks: [],
    text: 'Перейти на сайт',
    textVisited: 'Просмотрено'
  }),

  methods: {
    numberFormat(num) {
      return String(num)
        .replace(/[^0-9]/g, "")
        .replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, "$1 ");
    },
    showcaseSort(e) {
      e.stopPropagation();

      this.showcaseSortActive = !this.showcaseSortActive;
    },
    goToLink(showcase, index) {
      this.visitedLinks.push(index);
     
      window.open(showcase.link, "_blank");
      
    }
  },
  computed: {
    showcaseItemsParts() {
      return this.showcaseItems.slice(0, this.showcaseCounter);
    },
    showcaseSortPercentUp() {
      this.showcaseItems.sort((a, b) => a.percent - b.percent);
    },
    showcaseSortPercentDown() {
      this.showcaseItems.sort((a, b) => b.percent - a.percent);
    },
    showcaseSortCashbackUp() {
      this.showcaseItems.sort((a, b) => a.cashback - b.cashback);
    },
    showcaseSortCashbackDown() {
      this.showcaseItems.sort((a, b) => b.cashback - a.cashback);
    },
    showcaseSortServiceUp() {
      this.showcaseItems.sort((a, b) => a.service - b.service);
    },
    showcaseSortServiceDown() {
      this.showcaseItems.sort((a, b) => b.service - a.service);
    },
  },

  mounted() {
    if (!Auth.getToken()) {
      return this.$router.replace({ name: "Login" });
    }

    Auth.load("/client/showcase?category=Дебетовые карты")
      .then((data) => {
        this.loading = false;
        this.date = data.date;
        this.firstname = data.firstname;
        this.showcaseItems = data.showcase;
      })
      .catch(() => {
        Auth.logout();
        this.$router.replace({ name: "Login" });
      });
      document.addEventListener("mousedown", (e) => {
      var container = document.getElementById("sorting");
      var containerBtn = document.getElementById("sorting-btn");
      if (!container?.contains(e.target) && !containerBtn?.contains(e.target) && e.target.id !== "sorting-btn") {
        this.showcaseSortActive = false;
      }
   
    });
  },
};
</script>
