<template>
  <div
    class="dropdown"
    @click="toggleRiskLevels"
    :class="{ expanded: isExpanded }"
    :style="computedStyles"
    :key="counter"
  >
    <div class="dropdown-label-container">
      <div class="dropdown-label">
        <div class="icons-wrapper">
          <div class="text">
            <div class="text-img">
              <img v-if="$route.path === '/showcase'" src="/img/showcase-emoji-second.svg" alt="" />
              
              <img v-if="$route.path !== '/showcase'" :src="config.icon" alt="" />

            </div>
            <div class="text-text">
              {{ config.placeholder ? config.placeholder : "" }}
            </div>
          </div>
        </div>

        <div class="" :class="{ toggled: isExpanded }">
          <img src="/img/down-arrow.svg" alt="" />
        </div>
      </div>
    </div>

    <div v-expand="isExpanded" class="options-wrapper options expand">
      <div
        v-for="option in configOptions"
        class="option"
        :class="{
          'showcase-option': option.path === '/showcase',
          'showcase-active-option':
            option.path === '/showcase' && $route.path === '/showcase',
        }"
        @click="setCurrentSelectedOption(option)"
        :key="option.path"
      >
        <div>
          <img
            v-if="$route.path === option.path && $route.path === '/showcase'"
            src="/img/showcase-emoji-second.svg"
            alt=""
          />
          <img
            v-else-if="
              $route.path !== option.path && $route.path === '/showcase'
            "
            :src="option.img"
            alt=""
          />
          <img v-else :src="option.img" alt="" />
        </div>
        <div
          class="text-text"
          :class="{
            'showcase-option-txt': option.path === '/showcase',
            'showcase-option-txt-active':
              option.path === '/showcase' && $route.path === '/showcase',
          }"
        >
          {{ option.value }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "dropdown",
  data() {
    return {
      isBottomSectionToggled: false,
      counter: 0,
      optionHeight: 35,
      isExpanded: false,
    };
  },
  components: {},
  props: ["config"],
  watch: {
    config: {
      deep: true,
      handler() {
        this.forceRenderer();
      },
    },
  },
  computed: {
    computedStyles: function() {
      return [
        { "--options-height": this.optionsHeight + "px" },
        { "--options-height-neg": "-" + this.optionsHeight + "px" },
        { "--option-height": this.optionHeight + "px" },
        { "--main-el-border-radius": this.borderRadius },
        { "--dropdown-width": 175 + "px" },
        { "--dropdown-background-color": this.backgroundColor },
        { "--dropdown-expanded-color": this.backgroundExpandedColor },
        { "--dropdown-border": this.border },
        { "--dropdown-hover-background-color": this.hoverBackgroundColor },
        { "--dropdown-default-text-color": this.textColor },
      ];
    },
    configOptions() {
      return this.config.options;
    },
    width() {
      return this.config.width;
    },
    placeholder() {
      return this.config.placeholder;
    },
    backgroundColor() {
      return this.config.backgroundColor || "#cde4f5";
    },
    backgroundExpandedColor() {
      return this.config.backgroundExpandedColor || "#fff";
    },
    border() {
      return this.config.border || "1px solid  #232b35";
    },
    hoverBackgroundColor() {
      return this.config.hoverBackgroundColor || "#0084d4";
    },
    textColor() {
      return this.config.textColor || "#fff";
    },
    borderRadius() {
      return this.config.borderRadius || 0;
    },
    optionsHeight() {
      return this.optionHeight * this.configOptions.length;
    },
  },
  directives: {
    expand: {
      mounted: function(el, binding) {
        if (binding.value !== null) {
          function calcHeight() {
            const currentState = el.getAttribute("aria-expanded");
            el.classList.add("u-no-transition");
            el.removeAttribute("aria-expanded");
            el.style.height = null;
            el.style.height = el.clientHeight + "px";
            el.setAttribute("aria-expanded", currentState);

            setTimeout(function() {
              el.classList.remove("u-no-transition");
            });
          }
          el.classList.add("expand");
          el.setAttribute("aria-expanded", binding.value ? "true" : "false");
          calcHeight();
          window.addEventListener("resize", calcHeight);
        }
      },
      updated: function(el, binding) {
        if (el.style.height && binding.value !== null) {
          el.setAttribute("aria-expanded", binding.value ? "true" : "false");
        }
      },
    },
  },
  methods: {
    forceRenderer() {
      this.$nextTick(() => {
        this.counter++;
      });
    },
    toggleRiskLevels() {
      this.isExpanded = !this.isExpanded;
    },
    setCurrentSelectedOption(option) {
      this.$emit("setSelectedOption", option);
    },
  },
};
</script>

<style>
.showcase-option {
  background: linear-gradient(90deg, #1193a7 0%, #31bb38 100%);
  border-radius: 1.5em;
}
.showcase-active-option {
  background: transparent;
}
.showcase-option-txt {
  color: #fff;
}
.showcase-option-txt-active {
  background: linear-gradient(90deg, #1193a7 0%, #31bb38 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: #1193a7;
  font-weight: 600;
}
.icons-wrapper {
  display: flex;
  align-items: center;
}
.text {
  display: flex;
  align-items: center;
}
.text-text {
  margin-left: 6px;
}
.dropdown {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: var(--dropdown-width);
  position: relative;
  border: 1px solid transparent;
  border-radius: var(--main-el-border-radius);
  cursor: pointer;
  color: var(--dropdown-default-text-color);
  background: var(--dropdown-background-color);
  user-select: none;
  transition: all 0.7s linear;
  padding: 3px 0px;
}
.dropdown .dropdown-label-container {
  display: flex;
  width: 100%;
}
.dropdown .dropdown-label-container .dropdown-label {
  display: flex;
  justify-content: space-between;
  flex: 1 1 auto;
  align-items: center;
  height: var(--option-height);
  padding: 4px 6px;
}
.dropdown .dropdown-label-container .dropdown-label .text {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 24px;
}
.dropdown .dropdown-label-container .dropdown-label .angle-down {
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid #fff;
  background: transparent;
  border-width: 0 2px 2px 0;
  padding: 4px;
  width: 1px;
  height: 1px;
  margin: -3px 5px 0 0;
  border-color: var(--dropdown-default-text-color);
  transform: rotate(45deg);
  transition: all 0.7s;
}
.dropdown .dropdown-label-container .dropdown-label .toggled {
  transform: rotate(-180deg);
  transition: all 0.7s;
}
.toggled {
  transform: rotate(-180deg);
  transition: all 0.7s;
}
.dropdown .options {
  width: 100%;
}
.dropdown .options .option {
  display: flex;
  align-items: center;
  padding: 4px 6px;
  height: 35px;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 18px;
}
.dropdown .options .option:hover {
  color: black;
  background: #cde4f5;
  transition: all 0.7s;
}
.dropdown .options .option:last-child {
  border-radius: 0 0 var(--main-el-border-radius) var(--main-el-border-radius);
}
.dropdown .options .option:last-child:hover {
  border-radius: 0 0 var(--main-el-border-radius) var(--main-el-border-radius);
}
.dropdown.expanded {
  background: var(--dropdown-expanded-color);
  border: var(--dropdown-border);
  transition: all 0.4s linear;
  padding: 5px 0px;
}
.expand {
  overflow: hidden;
  transition-property: height;
  transition-duration: 0.4s;
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.expand[aria-expanded="false"] {
  height: 0 !important;
  transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045);
}
.expanded .dropdown-label {
  padding: 4px 10px;
}

</style>
