<style>
img {
  max-width: 100%;
}
.dropdown {
  background: linear-gradient(270deg, #dcfcf0 0%, #d9f4f2 100%);
}
.dropdown.expanded {
  background: #fafafa;
}
.header-wrapper-just {
  display: flex;
  justify-content: space-between !important;
  margin-top: 30px;
  align-items: center;
}
.logout {
  display: flex;
  align-items: center;
}
.logout-btn {
  display: flex;
  align-items: center;
  padding: 7px 12px;
  background: linear-gradient(
    180deg,
    rgba(245, 245, 245, 1) 0%,
    rgba(224, 224, 224, 1) 100%
  );
  border-radius: 35px;
}

.logout-txt {
  color: #898a8f;
  font-size: 9px;
  margin-left: 7px;
}
.dropdown .dropdown-label-container .dropdown-label .text {
  font-family: "Jost", sans-serif;
  font-size: 10px !important;
  font-style: normal;
  font-weight: 500;
}
.dropdown .options .option {
  font-family: "Jost", sans-serif;
  font-size: 10px !important;
  font-style: normal;
  font-weight: 500;
}
.menu-wrapper {
  border-radius: 15px;
  background: #fff;
  width: 100%;
}
.nav-menu {
  display: none;
}
.menu {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding: 9px 22px;
  margin-top: 60px;
  margin-bottom: 60px;
}
.menu-mobile {
  display: none;
}
.username-top {
  display: block;
  margin-right: 20px;
}
.username-menu {
  display: none;
}
.username-top,
.username-menu {
  color: #000;

  font-family: Jost;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.account-menu {
  display: flex;
  align-items: center;
}
.menu-path:not(:first-child) {
  margin-left: 15px;
}

.menu-path-wrapper {
  display: flex;
  align-items: center;
  border-radius: 50px;
  background: linear-gradient(90deg, #dcfcf0 0%, #d9f4f2 100%);
  padding: 6px 15px 6px 8px;
}
.showcase-menu-item {
  background: linear-gradient(90deg, #1193a7 0%, #31bb38 100%);
}

.menu-path-active {
  display: flex;
  align-items: center;
  border-radius: 50px;
  background: #fafafa;
  padding: 6px 15px 6px 8px;
}
.menu-path-active-showcase {
  background: transparent;
}
.menu-path-txt {
  color: #3e3d3d;
  font-family: Jost;
  font-size: 9px;
  font-style: normal;
  font-weight: 500;
  margin-left: 8px;
}
.showcase-menu-item-txt {
  color: #fff;
}
.menu-path-active-txt {
  color: #3e3d3d;
}
.menu-path-active-txt-showcase {
  background: linear-gradient(90deg, #1193a7 0%, #31bb38 100%);

  font-weight: 600;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: #1193a7;
}
@media (max-width: 768px) {
  .menu {
    display: none;
  }
  .menu-mobile {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    padding: 9px 15px;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .nav-menu {
    display: block;
  }
  .username-top {
    display: none;
  }
  .username-menu {
    display: block;
  }
  .account-menu {
    display: none;
  }
  .dropdown-wrapper {
    width: 180px;
    height: 45px;
    z-index: 99;
  }
  .nav-menu {
    position: absolute;
  }
}
@media (max-width: 480px) {
  .menu {
    padding: 6px 20px;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .menu-mobile {
    padding: 9px 65px;
  }
  .header-wrapper-just img {
    max-width: 72%;
  }
  .partner-card {
    width: 46%;
  }
  .partner-btn-show-more {
    width: 60%;
  }
  .logout-txt {
    margin-left: 0;
  }
}
@media (max-width: 433px) {
  .menu-mobile {
    padding: 9px 40px;
  }
}
@media (max-width: 410px) {
  .menu-mobile {
    padding: 9px 25px;
  }
}
@media (max-width: 376px) {
  .menu-mobile {
    padding: 9px 12px;
  }
}
</style>

<template>
  <div class="container">
    <div class="header-wrapper" :class="{ 'header-wrapper-just': !isIndex }">
      <div class="logo-wrapper">
        <a href="https://365zaym.su" target="_blank">
          <img src="/img/logo.png" alt="" />
        </a>
      </div>
      <div v-if="!isIndex" class="logout">
        <div v-if="firstname !== ''" class="username-top">
          {{ firstname }} {{ lastname?.charAt(0) }}.
        </div>
        <div v-if="firstname === ''" class="username-top">
          Ув. пользователь
        </div>
        <div class="logout-btn-wrapper">
          <button class="logout-btn">
            <div><img src="img/logout-icon.svg" alt="" /></div>
            <div @click="exit" class="logout-txt">Выйти</div>
          </button>
        </div>
      </div>
    </div>
    <div v-if="!isIndex" class="menu-wrapper">
      <div class="menu">
        

        <div class="account-menu">
          <div :key="item.path" v-for="item in menuOptions" class="menu-path">
            <router-link :to="{ path: item.path }" role="button">
              <div
                :class="{
                  'showcase-menu-item': item.path === '/showcase',
                  'menu-path-active': $route.path === item.path,
                  'menu-path-active-showcase':
                    $route.path === item.path && $route.path === '/showcase',
                }"
                class="menu-path-wrapper"
              >
                <div class="icon">
                  <img
                    v-if="
                      $route.path === item.path && $route.path === '/showcase'
                    "
                    src="/img/showcase-emoji-second.svg"
                    :alt="item.value"
                  />
                  <img
                    v-else-if="
                      $route.path !== item.path && $route.path === '/showcase'
                    "
                    :src="item.img"
                    :alt="item.value"
                  />
                  <img v-else :src="item.img" :alt="item.value" />
                </div>
                <div
                  :class="{
                    'showcase-menu-item-txt': item.path === '/showcase',
                    'menu-path-active-txt': $route.path === item.path,
                    'menu-path-active-txt-showcase':
                      $route.path === item.path && $route.path === '/showcase',
                  }"
                  class="menu-path-txt"
                >
                  {{ item.value }}
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="!isIndex" class="menu-wrapper">
    <div class="menu-mobile">
      <div class="username-menu">
        <div v-if="firstname !== ''">{{ firstname }} {{ lastname?.charAt(0) }}.</div>
      </div>
      <div class="username-menu">
        <div v-if="firstname === ''">Ув. пользователь</div>
      </div>

      <div class="dropdown-wrapper">
        <vue-dropdown
          class="nav-menu"
          :config="config"
          @setSelectedOption="setNewSelectedOption($event)"
        ></vue-dropdown>
      </div>
    </div>
  </div>
  <router-view :setRating="setRating" />
</template>

<script>
import * as Auth from "@/assets/js/auth.js";
import VueDropdown from "@/components/VueDropdown.vue";

export default {
  name: "App",

  components: {
    VueDropdown,
  },

  data: () => ({
    rating: "0",
    category: [],
    icon: null,
    placeholder: null,
  }),
  created() {
    this.$router.beforeEach(async (to, from, next) => {
      if (to.name !== "Login") {
        await Auth.load("/client/showcase")
          .then((data) => {
            this.category = data.categories;
          })
          .catch(() => {
            Auth.logout();
            router.replace({ name: "Login" });
          });
      }
      this.placeholder = this.config.options.find(
        (option) => option.path === to.path
      )?.value;
      this.icon = this.config.options.find(
        (option) => option.path === to.path
      )?.img;
      next();
    });
    Auth.setTokenFromQuery();
    if (!Auth.getToken()) {
      const query = Object.fromEntries(
        new URLSearchParams(location.search.substring(1))
      );
      this.$router.replace({ name: "Login", query });
    } else {
      this.$router.replace(location.pathname);
    }
  },
  methods: {
    setRating(rating) {
      this.rating = String(rating);
      localStorage.setItem("rating", rating);
    },
    onCloseMenu() {
      this.showMenu = false;
    },
    exit() {
      Auth.logout();
      this.$router.replace({ name: "Login" });
    },
    setNewSelectedOption(selectedOption) {
      this.$router.replace({ path: selectedOption.path });
    },
  },

  computed: {
    isIndex() {
      return this.$router.currentRoute.value.name === "Login";
    },
    firstname() {
      return JSON.parse(localStorage.getItem("profile"))?.firstname;
    },
    lastname() {
      return JSON.parse(localStorage.getItem("profile"))?.lastname;
    },
    menuOptions() {
      return [
        {
          value: "Заявка",
          path: "/profile",
          img: "/img/person.svg",
        },
        {
          value: "Витрина предложений",
          path: "/showcase",
          img: "/img/showcase-emoji.svg",
          param: "МФО",
        },
        {
          value: "Кредитные карты",
          param: "Кредитные карты",
          path: "/creditCards",
          img: "/img/credit-cards.svg",
        },
        {
          value: "Дебетовые карты",
          param: "Дебетовые карты",
          path: "/debitCards",
          img: "/img/debit-cards.svg",
        },
        {
          value: "Списание долгов",
          path: "/bankru",
          img: "/img/bankru.svg",
        },
      ].filter((item) => !item.param || this.category?.includes(item.param));
    },
    config() {
      return {
        options: this.menuOptions,
        placeholder: this.placeholder,
        icon: this.icon,
        backgroundColor: "#cde4f5",
        textColor: "black",
        borderRadius: "1.5em",
        border: "1px solid gray",
        width: 160,
      };
    },
  },
  mounted() {
    if (!Auth.getToken()) {
      return this.$router.replace({ name: "Login" });
    }
  },
};
</script>
