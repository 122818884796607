import axios from 'axios';

var key;
var token;

var client = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL,
});

var entry = function (phone, host) {
	return client.post('/client/authorization/entry', {
        phone: phone,
        host: host
	}).then(response => {
		key = response.data.key;
		token = response.data.token;
		return response.data;
    });
};

var confirm = function (code) {
	return client.post('/client/authorization/confirm', {
		key: key,
		token: token,
		code: code
	}).then(response => {
		if (response.data.bearer) {
			localStorage.setItem('token', response.data.bearer);
		}
		return response.data;
    });
};
var changePhoneRequest = function (phone, host) {
	var config = {headers: {'Authorization': 'Bearer ' + getToken()}};
	return client.post('/client/change-phone/request', {
        phone: phone,
        host: host
	}, config).then(response => {
		key = response.data.key;
		token = response.data.token;
		return response.data;
    });
};

var changePhoneConfirm = function (code) {
	var config = {headers: {'Authorization': 'Bearer ' + getToken()}};
	return client.post('/client/change-phone/confirm', {
		key: key,
		token: token,
		code: code
	}, config).then(response => {
		return response.data;
    });
};


var logout = function () {
	var config = {headers: {'Authorization': 'Bearer ' + getToken()}};
	localStorage.removeItem('token');
	return client.post('/client/authorization/logout', {}, config);
};

var getToken = function() {
	return localStorage.getItem('token');
};

var setTokenFromQuery = function() {
	let url = new URL(location.href);
	let token = (new URLSearchParams(url.search)).get("bearer");

	if (token) {
		localStorage.setItem('token', token);
	}
};

var load = function(endpoint, data) {
	if (typeof data === 'undefined') {
		data = {};
	}
	var config = {headers: {'Authorization': 'Bearer ' + getToken()}};
	return client.post(endpoint, data, config).then(response => response.data);
};

var oauth2 = function(client) {
	const host = location.hostname.replace("lk.", "")
	const redirect_url = location.origin + "/profile"


	// return window.open(process.env.VUE_APP_API_BASE_URL + `/oauth2/client/${client}/connect?host=${host}&redirect_url=${redirect_url}`, "_blank");
	return window.location.replace(process.env.VUE_APP_API_BASE_URL + `/oauth2/client/${client}/connect?host=${host}&redirect_url=${redirect_url}&host_type=cabinet`);
};

export { entry, confirm, logout, getToken, load, setTokenFromQuery, oauth2, changePhoneRequest, changePhoneConfirm };
